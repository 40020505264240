<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">Items Setup</h3>
      <ul>
        <li>
          <a href="index.html">Items</a>
        </li>
        <li>All Items</li>
      </ul>
    </div>
    <div> -->
    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Item",
        path: "items",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Type"),
            value: "item_type",
          },
          {
            text: this.$i18n.t("Category"),
            value: "category",
          },
          {
            text: this.$i18n.t("Cost Price"),
            value: "cost",
          },
          {
            text: this.$i18n.t("Selling Price"),
            value: "selling_price",
          },
          {
            text: this.$i18n.t("Allow Discount"),
            value: "allow_discount",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Items"),
        apiEndPoints: {
          list: "items",
          create: "items/store",
          edit: "items/update",
          delete: "items/destroy",
        },
        editedItem: {
          en: { name: "" },
          ar: { name: "" },
          item_type: "",
          selling_price: "",
          cost: "",
          item_category_id: "",
          allow_discount: "",
        },
        permissions: {
          list: "list-items",
          add: "add-items",
          edit: "edit-items",
          delete: "delete-items",
          import: "import-items",
          export: "export-items",
        },
        filter: {
          type: [],
          category_id: [],
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {
    // this.table.headers = this.table.headers.filter(function(row) {
    //   return row.value != "action";
    // });
  },
};
</script>

<style></style>
